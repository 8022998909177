import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './actions';
import Table from '../../components/simple/Table';
import './CardTable.scss';
import columns from './table.settings';
import CsvDownload from 'react-json-to-csv';
import { DownloadOutlined } from '@ant-design/icons';

const CardTable = ({ dashboard, name, loading, mapper, ...props }) => {

  const formatRange = (range) => range ? `${range.start.format('YYYY-MM-DD')}_${range.end.format('YYYY-MM-DD')}` : '';

  const handleMapper = () => {
    let data = dashboard[name]?.exportable || dashboard[name]?.result || [];
    return mapper ? mapper(data) : data;
  };

  const nameSuffix = formatRange(dashboard?.range[name]);

  return (
    <div className={`CardTable ${name}`}>
      <Table columns={columns[name]} data={dashboard[name]} {...props} />
      {!loading && (
        <CsvDownload data={handleMapper()} filename={`${name}__${nameSuffix}.csv`}>
          <DownloadOutlined />
        </CsvDownload>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  dashboard: state.get('dashboard'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(Actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CardTable);