import { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './actions';
import { Row, Col } from 'antd';
import Card from '../../components/simple/Card';
import CardTitle from './CardTitle';
import CardTable from './CardTable';
import DynamicFilters from '../../components/custom/DynamicFilters';
import './DashboardPage.scss';

function Dashboard(props) {
  const { dashboard, setRange, tableProps, getAbsences, getDelays, getCancellations } = props;
  const { loading, range, page, orderBy } = dashboard;
  const [filters, setFilters] = useState({});

  const fetchData = (name, fetchFunction) => {
    if (range[name]) fetchFunction(range[name], filters, page, orderBy);
  };

  useEffect(() => fetchData('absences', getAbsences), [range['absences']]);
  useEffect(() => fetchData('delays', getDelays), [range['delays']]);
  useEffect(() => fetchData('cancellations', getCancellations), [range['cancellations'], filters, page, orderBy]);

  const renderCard = (name, title) => (
    <Col className='inside' xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
      <Card title={<CardTitle advanced onChange={(value) => setRange(name, value)} title={title} />}>
        <CardTable mapper={(data) => data.map((item) => ({
          ...item,
          details: item.details.map(detail => ({
            trackingID: detail.trackingID,
            type: detail.type,
            startDate: detail.startDate,
            delayMinutes: detail.delayMinutes,
            clientName: detail.client.name,
            groupName: detail.group.name
          }))
        }))}
        name={name}
        loading={loading[name.toUpperCase()]?.state}
      />
      </Card>
    </Col>
  );

  return (
    <div className='Dashboard'>
      <Row className='top-row'>
        {renderCard('absences', 'Ausencias')}
        {renderCard('delays', 'Retardos')}
      </Row>
      <Row>
        <Col className='inside' xs={24}>
          <Card title={<CardTitle advanced onChange={(value) => setRange('cancellations', value)} title='Cancelaciones' />}>
            <DynamicFilters
              {...props}
              onChange={(newFilters) => tableProps(1).then(() => setFilters(newFilters))}
              data={dashboard.cancellations}
            />
            <CardTable
              mapper={(data) =>
                (data || []).map((item) => ({
                  ...item,
                  replacements: (item.replacements || []).map((rep) => rep.date),
                }))
              }
              onChange={(pager, _, sort) => tableProps(pager.current, sort)}
              name='cancellations'
              loading={loading.CANCELLATIONS?.state}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default connect(
  (state) => ({ dashboard: state.get('dashboard') }),
  (dispatch) => bindActionCreators(Actions, dispatch)
)(Dashboard);
